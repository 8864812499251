<template>
  <div v-if="loaded">
    <h4>Generate front page letter</h4>
    <div class="card">
    <div class="card-body">
      <form @submit.prevent="generateLetterCover()">
        <div class="row">
          <div class="col-md-4">
            <!-- Insert form with Receiver, Adress line 1, adress line 2, postal code, and city -->
            <a-form-item label="Receiver">
              <a-input v-model="data.receiver" />
            </a-form-item>
            <a-form-item label="Adress line 1">
              <a-input v-model="data.adressLine1" />
            </a-form-item>
            <a-form-item label="Adress line 2">
              <a-input v-model="data.adressLine2" />
            </a-form-item>
            <a-form-item label="Postal code">
              <a-input v-model="data.postalCode" />
            </a-form-item>
            <a-form-item label="City">
              <a-input v-model="data.city" />
            </a-form-item>
            <!-- Insert select for return adress. these options will be loaded thru firebase -->
            <a-form-item label="Return adress">
              <a-select v-model="data.returnAdress">
                <a-select-option v-for="adress in returnAdresses" :key="adress.value" :value="adress.value">{{ adress.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <a-button style="width: 300px;" type="primary" htmlType="submit" class="mt-3">Generate front page letter</a-button>
          </div>
        </div>
      </form>
    </div>
    </div>
  </div>
</template>

<script>
import { functions, firestore } from '@/services/firebase'
export default {
  data() {
    return {
      signature: '',
      loaded: false,
      data: {},
      returnAdresses: [],
    }
  },
  mounted() {
    const companies = this.$store.state.user.companies
    console.log(companies)
    companies.forEach((company) => {
      firestore.collection('settings').doc('companies').collection('companies').doc(company)
        .get()
        .then(doc => {
          const adress = {
            value: `${doc.data().name} ${doc.data().address.addressLine1} ${doc.data().address.postalCode} ${doc.data().address.city}`,
            label: `${doc.data().name} ${doc.data().address.city}`,
          }
          this.returnAdresses.push(adress)
        })
    })
    console.log(this.returnAdresses)
    this.loaded = true
  },
  methods: {
    generateLetterCover() {
      const generateLetterCover = functions.httpsCallable('generateLetterCover')
      generateLetterCover(this.data)
        .then(result => {
          console.log(result)
          this.signature = result.data
        })
    },
  },
}
</script>
